<template>
  <div>
    <div class="top-bar flex flex-col justify-between">
      <div class="account-title">Profile</div>
      <div class="nav-bar flex justify-between sm:justify-start">
        <div
          :class="['bar-list', { is_active: currentTab === 'ProfileAccount' }]"
          v-on:click="currentTab = 'ProfileAccount'"
        >
          Account
        </div>
        <div
          :class="['bar-list', { is_active: currentTab === 'ProfileSecurity' }]"
          v-on:click="currentTab = 'ProfileSecurity'"
        >
          Security
        </div>
        <div
          :class="['bar-list', { is_active: currentTab === 'ProfileLimited' }]"
          v-on:click="currentTab = 'ProfileLimited'"
        >
          Limited
        </div>
      </div>
    </div>
    <div class="bottom">
      <transition mode="out-in">
        <component v-bind:is="currentTabComponent"></component>
      </transition>
    </div>
  </div>
</template>

<script>
import ProfileAccount from '@/components/User/ProfileAccount.vue'
import ProfileLimited from '@/components/User/ProfileLimited.vue'
import ProfileSecurity from '@/components/User/ProfileSecurity.vue'
import { mapState } from 'vuex'

export default {
  created () {
  },
  components: {
    ProfileAccount,
    ProfileLimited,
    ProfileSecurity
  },
  data () {
    return {
      tabs: ['Account', 'Security', 'Limited'],
      currentTab: 'ProfileAccount'
    }
  },
  computed: {
    currentTabComponent () {
      return this.currentTab
    },
    ...mapState('login', ['user'])
  }
}

</script>

<style scoped>
.marginRL60 {
  margin: 0 60px;
}
.bottom {
  padding-top: 25px;
  padding-left: 24px;
  display: flex;
}
.is_active {
  border-bottom: 3px solid #bc9a41;
}

.v-enter{
  opacity: 0;
  transform: translateX(50%);
}
.v-leave-to {
  opacity: 0;
  transform: translateX(-50%);
}
.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}
@media only screen and (max-width: 600px) {
.marginRL60 {
  margin: 0 60px;
}
.bottom {
  padding: 5vw;
  display: flex;
}
.is_active {
  border-bottom: 3px solid #bc9a41;
  color: #163257;
}

}
</style>
