<template>
  <div
    class="base-switch-btn"
    @click="handleSwitch"
    :class="[isTurnOn ? 'toggleBg' : '']"
  >
    <div class="ball" :class="[isTurnOn ? 'toggleLeft' : '']"></div>
  </div>
</template>

<script>
export default {
  created () {
    this.isTurnOn = this.propIsTurnOn
  },
  data () {
    return {
      isTurnOn: Boolean
    }
  },
  props: {
    propIsTurnOn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSwitch () {
      this.isTurnOn = !this.isTurnOn
    }
  }
}

</script>

<style scoped>
/* #BFBFBF
  #BC9A41 */
.base-switch-btn {
  width: 44px;
  height: 22px;
  box-sizing: border-box;
  background: #bfbfbf;
  border-radius: 11px;
  position: relative;
}
.ball {
  background-color: #ffffff;
  height: 18px;
  width: 18px;
  border-radius: 20px;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: all 0.3s;
}
.toggleLeft {
  left: 24px;
}
.toggleBg {
  background-color: #bc9a41;
}
</style>
