<template>
  <div class="security-container "
  @click="handleC"
  >
    <div class="security-top">
      <div>
        <div class=" text-14px sm:text-18px text-black-85">安全问题</div>
        <div class="sm:text-14px text-12px  text-black-45 marginTop10">
          为了将来能恢复您的密码，请设置密码保护问题。
        </div>
      </div>
      <div v-if="!isExpand" class="goldBtn" >编辑</div>
      <div v-else class="cursor-pointer">
        X
      </div>
    </div>
    <transition name="expand">
      <div v-if="isExpand"
      @click.stop
      class="security-bottom">
        <input
          class="input-style width440"
          type="password"
          placeholder="当前密码"
        />
        <select
          class="input-style width440 marginTop16"
          type="password"
          placeholder="选择密码保护问题"
        />
        <input
          class="input-style width440 marginTop16"
          type="password"
          placeholder="询问"
        />

        <input
          class="submit-gray width100 marginTop32"
          type="submit"
          value="保存"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isExpand: false
    }
  },
  methods: {
    handleC () {
      this.isExpand = !this.isExpand
      console.log('as')
    }
  }
}

</script>

<style scoped>
.security-bottom {
  width: 100%;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 0px 0px 4px 4px;
  padding: 32px 328px 48px 32px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
}
.width440 {
  width: 440px;
}
.security-top {
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 24px 20px;
  box-sizing: border-box;
  width: 100%;
}
.security-top:hover {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background: #d7dde4;
}
.security-container {
  width: 800px;
  box-sizing: border-box;
  margin-bottom: 16px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background: #d7dde4;
}
.width140px {
  width: 140px;
}
@media only screen and (max-width:600px){
.security-container {
  width: auto;
  box-sizing: border-box;
  margin-bottom: 16px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background: #d7dde4;
}
.width440 {
  width: 100%;
}
.security-top {
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2vh;
  box-sizing: border-box;
  width: 100%;
}
.marginTop10{
  margin-top: 1vh;
}
.security-bottom {
  width: 100%;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 0px 0px 4px 4px;
  padding: 5vw;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
}
.input-style{
  height: 6vh;
}
.input-style::placeholder{
  font-size: 14px;
}
.marginTop16{
  margin-top: 1vh;
}
.marginTop32{
  margin-top: 2vh;
}
.submit-gray{
  height: 6vh;
}

}
</style>
