<template>
  <div>
    <BaseResetPwd></BaseResetPwd>
    <BaseSecurityQuestion class="abc"></BaseSecurityQuestion>
    <div class="securityUnit">
      <div>
        <div class="alignCenter">
          <span class="sm:text-18px text-14px text-black-85">2步验证</span
          ><span class="marginLeft16 sm:text-14px text-12px text-black-85">状态: </span
          ><span class="sm:text-14px text-12px colorSuccess">已启用</span
          ><span class="sm:text-14px text-12px colorDangerous">已禁用</span>
        </div>
        <div class="sm:text-14px text-12px text-black-45 marginTop10">
          通过启用2步验证来保证您的账户安全。
        </div>
      </div>
      <div class="goldBtn">启用</div>
    </div>
  </div>
</template>

<script>
import BaseResetPwd from '@/components/User/BaseResetPwd.vue'
import BaseSecurityQuestion from '@/components/User/BaseSecurityQuestion.vue'

export default {
  components: {
    BaseResetPwd,
    BaseSecurityQuestion
  }
}

</script>

<style scoped>
.securityUnit {
  width: 800px;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 24px 20px;
  margin-bottom: 16px;
}

.securityUnit:hover {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background: #d7dde4;
}
@media only screen and (max-width:600px){
  .securityUnit {
  width: 90vw;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 2vh;
  margin-bottom: 16px;
}
.marginLeft16{
  margin-left: 2vw;
}

.securityUnit:hover {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background: #d7dde4;
}
}
</style>
