<template>
  <div class="profile-account-container">
    <div class="profile-account-left">
      <div class="profile-person">
        <div class="account-title">
Personalisation</div>
        <div class=" grid grid-cols-3 grid-rows-4">
          <div class="account-text2 marginTop32">Name</div>
          <div class=" pl-8vw sm:pl-16 account-content marginTop32 weight600 fontBlack85 text-left">
              {{ user.mist.firstname + ' ' + user.mist.lastname}}
            </div>
          <div></div>
          <div class="account-text2 marginTop32">Account-Level</div>
          <div class=" pl-8vw sm:pl-16 text-left account-content marginTop32 weight600 fontBlack85">
              {{ user.status }}
            </div>
          <div
              class=" goldBtn text-right marginTop32"
              v-on:click="$router.push('/user/updatelevel')"
            >
              Upgrade
            </div>
          <div class="account-text2 marginTop32">Language</div>
          <div class=" pl-8vw sm:pl-16 account-content marginTop32 text-left">{{ user.language }}</div>
          <div class="marginTop25 goldBtn text-right marginTop32">Edit</div>
          <div class="account-text2 marginTop32">Residential</div>
          <div class=" pl-8vw sm:pl-16 account-content marginTop32 text-left">{{ user.mist.country_code }}</div>
          <div></div>
        </div>
      </div>
      <div class="profile-address">
        <div class="account-title">Address</div>
        <div class="marginTop32 text-14px  flex3column ">
          <div class=" sm:w-auto w-50vw">{{ user.mist.address }}</div>
          <div class="goldBtn">Update</div>
        </div>
      </div>
    </div>
    <div class="profile-account-right">
      <div class="profile-email">
        <div class="flex3column alignCenter">
          <div class="account-title ">Email-Address</div>
          <div class="font14 shrink0">
            状态:
            <span v-if="user.isEmailVertify" class="font14 colorSuccess"
              >已认证</span
            >
            <span v-else class="font14 colorDangerous">未认证</span>
          </div>
        </div>
        <div class="flex3column alignCenter marginTop32">
          <div class="fontBlack85 text-14px sm:text-16px weight600">{{ user.mist.email }}</div>
          <div class="goldBtn" v-if="!user.isEmailVertify">Verify</div>
        </div>
        <div class="flex3column alignCenter marginTop32">
          <div class="fontBlack85 text-14px sm:text-16px weight600">电子营销偏好</div>
          <BaseSwitchBtn></BaseSwitchBtn>
        </div>
        <div class="font14 marginTop10">
          您当前接收经由电子邮件发送的新闻和特价优惠。<br />我们只会每隔一段时间给您发送相关的消息。
        </div>
      </div>
      <div class="profile-phone">
        <div class="account-title">Contact</div>
        <div class="flex3column alignCenter marginTop32">
          <div class="fontBlack85 text-14px sm:text-16px weight600">电子营销偏好</div>
          <div class="goldBtn" v-if="!user.isEmailVertify">edit</div>
        </div>
        <div class="flex3column alignCenter marginTop32">
          <div class="fontBlack85 text-14px sm:text-16px weight600">SMS营销偏好</div>
          <BaseSwitchBtn :propIsTurnOn="true"></BaseSwitchBtn>
        </div>
        <div class="font14 marginTop10">
          您目前并无通过短信接收新闻和优惠。<br />我们只会适时地向您发送相关消息。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSwitchBtn from '@/components/BaseSwitchBtn.vue'
import { mapState } from 'vuex'

export default {
  components: {
    BaseSwitchBtn
  },
  data () {
    return {
      test: false
    }
  },
  props: {
  },
  computed: {
    ...mapState('login', ['user'])
  }
}

</script>

<style scoped>
.profile-account-container {
  display: flex;
}
.profile-account-right {
  width: 540px;
  flex-shrink: 0;
  margin-left: 16px;
}
.profile-account-left {
  width: 540px;
  flex-shrink: 0;
}
.profile-person {
  background: #ffffff;
  padding: 32px 40px 32px 32px;
  border-radius: 4px;
}
.profile-email {
  background: #ffffff;
  padding: 32px 40px 32px 32px;
  border-radius: 4px;
}
.profile-phone {
  margin-top: 16px;
  padding: 32px 40px 32px 32px;
  border-radius: 4px;
  background: #ffffff;
}
.profile-address {
  border-radius: 4px;
  margin-top: 14px;
  padding: 32px 40px 32px 32px;
  background: #ffffff;
}
.flex3column {
  display: flex;
  justify-content: space-between;
}
.goldBtn {
  text-decoration: underline;
  color: #bc9a41;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}
@media only screen and (max-width:600px){
.profile-account-container {
  display: block;
  width: 100%;
}
.marginTop32{
  margin-top: 2vh;
}
.profile-account-right {
  width: 100%;
  flex-shrink: 0;
  margin-left: 0;
}
.profile-account-left {
  width: 100%;
  flex-shrink: 0;
}
.profile-person {
  background: #ffffff;
  padding: 5vw;
  border-radius: 4px;
}
.profile-email {
  background: #ffffff;
  padding: 0 5vw 5vw 5vw;
  border-radius: 4px;
}
.profile-phone {
  margin-top: 16px;
  padding: 5vw;
  border-radius: 4px;
  background: #ffffff;
}
.profile-address {
  border-radius: 4px;
  margin-top: 14px;
  padding: 5vw;
  width: 100%;
  background: #ffffff;
}
.flex3column {
  display: flex;
  justify-content: space-between;
}
.goldBtn {
  text-decoration: underline;
  color: #bc9a41;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
.account-text2{
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 16px;
}
.account-content{
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 16px;
}
.font14{
  font-size: 12px;
}
}
</style>
