<template>
  <div class="security-container"
  @click="isExpand = !isExpand"
  >
    <div class="security-top">
      <div>
        <div class=" sm:text-18px 14px text-black-85">密码</div>
        <div class="sm:text-14px text-12px text-black-45 marginTop10">修改密码</div>
      </div>
      <div v-if="!isExpand" class="goldBtn" >编辑</div>
      <div v-else  class="cursor-pointer ">
        X
      </div>
    </div>
    <transition name="expand">
      <div
      @click.stop
      v-if="isExpand" class="security-bottom">
        <input
          class="input-style width440"
          type="password"
          placeholder="当前密码"
        />
        <input
          class="input-style width440 marginTop16"
          type="password"
          placeholder="新密码"
        />
        <input
          class="input-style width440 marginTop16"
          type="password"
          placeholder="确认新密码"
        />
        <div class="font14 marginTop16 alignCenter flex">
          您的出生日期
          <img
            style="width: 16px; height: 16px; margin-left: 8px"
            src="@/assets/icon/question.png"
          />
        </div>
        <div>
        <input class="input-style width140px" type="text" placeholder="日" />
        <input
          class="input-style width140px marginLeft10"
          type="text"
          placeholder="月"
        />
        <input
          class="input-style width140px marginLeft10"
          type="text"
          placeholder="年"
        />
        <input
          class="submit-gray width100 marginTop32"
          type="submit"
          value="保存"
        />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  data () {
    return {
      isExpand: false
    }
  }
}

</script>

<style scoped>
.security-bottom {
  width: 100%;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 0px 0px 4px 4px;
  padding: 32px 328px 48px 32px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
}
.width440 {
  width: 440px;
}
.security-top {
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 24px 20px;
  box-sizing: border-box;
  width: 100%;
}
.security-top:hover {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background: #d7dde4;
}
.security-container {
  width: 800px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.width140px {
  width: 140px;
}
@media only screen and (max-width:600px){
.security-bottom {
  width: 100%;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 0px 0px 4px 4px;
  padding: 5vw;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
}
.submit-gray{
  height: 6vh;
  background: #163257;
  border-radius: 4px;
  opacity: 0.2;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  box-sizing: border-box;
  appearance: none;
}
.width440 {
  width: 100%;
}
.security-top {
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.3vh 2vh;
  box-sizing: border-box;
  width: 100%;
}
.security-top:hover {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  background: #d7dde4;
}
.security-container {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.marginTop16{
  margin-top: 1vh;
}
.marginTop32{
  margin-top: 2vh;
}
.width140px {
  width: 30%;
}

.input-style{
  height: 6vh;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  appearance: none;
  outline: none;
  box-sizing: border-box;
  padding-left: 16px;
}
.input-style::placeholder{
  font-size: 14px;
}
}
</style>
